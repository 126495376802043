import IDEE$control$IGNSearchLocatorControl from './facade/js/ignsearchlocatorcontrol';
import IDEE$control$InfoCatastroControl from './facade/js/infocatastrocontrol';
import IDEE$plugin$Locator from './facade/js/locator';
import IDEE$control$LocatorControl from './facade/js/locatorcontrol';
import IDEE$control$XYLocatorControl from './facade/js/xylocatorcontrol';
import IDEE$impl$control$IGNSearchLocatorControl from './impl/ol/js/ignsearchlocatorcontrol';
import IDEE$impl$control$InfoCatastroControl from './impl/ol/js/infocatastrocontrol';
import IDEE$impl$control$LocatorControl from './impl/ol/js/locator';
import IDEE$impl$control$XYLocatorControl from './impl/ol/js/xylocatorcontrol';

if (!window.IDEE.control) window.IDEE.control = {};
if (!window.IDEE.plugin) window.IDEE.plugin = {};
if (!window.IDEE.impl) window.IDEE.impl = {};
if (!window.IDEE.impl.control) window.IDEE.impl.control = {};
window.IDEE.control.IGNSearchLocatorControl = IDEE$control$IGNSearchLocatorControl;
window.IDEE.control.InfoCatastroControl = IDEE$control$InfoCatastroControl;
window.IDEE.plugin.Locator = IDEE$plugin$Locator;
window.IDEE.control.LocatorControl = IDEE$control$LocatorControl;
window.IDEE.control.XYLocatorControl = IDEE$control$XYLocatorControl;
window.IDEE.impl.control.IGNSearchLocatorControl = IDEE$impl$control$IGNSearchLocatorControl;
window.IDEE.impl.control.InfoCatastroControl = IDEE$impl$control$InfoCatastroControl;
window.IDEE.impl.control.LocatorControl = IDEE$impl$control$LocatorControl;
window.IDEE.impl.control.XYLocatorControl = IDEE$impl$control$XYLocatorControl;
